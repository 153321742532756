'use client'

import { Tables } from '@/types/supabase-generated'
import { User } from '@supabase/supabase-js'
import Avatar from 'react-avatar'

export const UserAvatar = ({ profile, user }: { profile: Tables<'profiles'>; user?: User }) => {
  const name = profile?.first_name + ' ' + profile?.last_name
  const display = (name.match(/\b\w/g) ? user?.email : name) || ''

  return <Avatar name={display} className='rounded-full max-w-8 max-h-8' size='2rem' />
}
